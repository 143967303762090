import { NgModule } from "@angular/core";
import { RouterModule, Routes, PreloadAllModules } from "@angular/router";
import { ContentLayoutComponent } from "./shared/components/layout/content-layout/content-layout.component";
import { FullLayoutComponent } from "./shared/components/layout/full-layout/full-layout.component";
import { content } from "./shared/routes/content-routes";
import { full } from "./shared/routes/full.routes";
import { AdminGuard } from "./shared/guard/admin.guard";
import { FacultyloginComponent } from "./pages/authentication/facultylogin/facultylogin.component";
import { StudentloginComponent } from "./pages/authentication/studentlogin/studentlogin.component";
import { LoginComponent } from "./pages/authentication/login/login.component";


const routes: Routes = [
  {
    path: "",
    redirectTo: "/authentication/prelogin",
    pathMatch: "full",
  },
  {
    path: "authentication/login",
    component: LoginComponent,
  },
  {
    path: "authentication/facultylogin",
    component: FacultyloginComponent,
  },
  {
    path: "authentication/studentlogin",
    component: StudentloginComponent,
  },
  {
    path: "",
    component: ContentLayoutComponent,
    canActivate: [AdminGuard],
    children: content,
  },
  {
    path: "",
    component: FullLayoutComponent,
    canActivate: [AdminGuard],
    children: full,
  },
  {
    path: "**",
    redirectTo: "",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      anchorScrolling: "enabled",
      scrollPositionRestoration: "enabled",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
