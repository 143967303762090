import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as alertifyjs from 'alertifyjs';
import { first } from 'rxjs/operators';
import { Subject } from 'rxjs/internal/Subject';
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { AccessservicelogService } from 'src/app/accessservicelog.service';
import { ServiceapiService } from 'src/app/serviceapi.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfigurationdropdownComponent } from '../configurationdropdown/configurationdropdown.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss','./login.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit ,OnDestroy {
  form: FormGroup;
  rows: any = [];
  hide = true;
  loading:boolean=false
  rows1: any = [];
  rows2: any = [];
  private unsubscribe$: Subject<void> = new Subject<void>(); // Use Subject<void>
  error = '';
 
  validationMessages = {
    email: [{ type: 'required', message: 'This field is required' }],
    password: [{ type: 'required', message: 'This field is required' }],
    
  };
  constructor(private formBuilder: FormBuilder,public dialog: MatDialog,private router: Router,  private serviceapi: ServiceapiService,private accessserviceapi:AccessservicelogService) { 
    this.form = this.formBuilder.group({
      email: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required),
    
    });
  }

  ngOnInit() {

   
   }
   ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
   emailValue:any
   passwordValue:any
  submitLogin(){
  
    const emailControl = this.form.get("email");
    const passwordControl = this.form.get("password");
    if (emailControl && emailControl.value) {
     this.emailValue = emailControl.value;
      
    } else {
     
    }
    if (passwordControl && passwordControl.value) {
      this.passwordValue = passwordControl.value;
       
     } else {
      
     }
    this.serviceapi
    .login(this.emailValue, this.passwordValue, "regular")
    .pipe(
      first(),
      takeUntil(this.unsubscribe$) // Automatically unsubscribes when component is destroyed
    ).subscribe(
            (data: any) => {
  
              this.rows = data;
        if (this.rows.status == 1) {
          localStorage.setItem('currentUser', JSON.stringify(this.rows.userdetails));
          localStorage.setItem('authToken', JSON.stringify(this.rows.userdetails[0].token));
          if(this.rows.userdetails[0].loginType == "staff"){
            if(this.rows.userdetails[0].degree == "UG"){
              this.router.navigate(['/faculty/internalmarksupload']);
            }
            if(this.rows.userdetails[0].degree == "PG"){
              this.router.navigate(['/post-exam/updategradesfaculty']);
            }
            this.accessserviceapi.AccessLog(this.rows.userdetails[0].name, "Login", "0.0.0.0",this.rows.userdetails[0].loginType).subscribe(resp => {
              this.rows2=resp.body;
              if(this.rows2.status == 1){
       
              }else{
    
              }
            });;
          }
          if(this.rows.userdetails[0].loginType == "student"){
            if(this.rows.userdetails[0].degree == "PG"){
              this.router.navigate(['/students/semestercourses']);
              
            }
            if(this.rows.userdetails[0].degree == "UG"){
              this.router.navigate(['/students/semestercourses']);
            }
            
            this.accessserviceapi.AccessLog(this.rows.userdetails[0].name, "Login", "0.0.0.0",this.rows.userdetails[0].loginType).subscribe(resp => {
              this.rows2=resp.body;
              if(this.rows2.status == 1){
                
              }else{
                
              }
            });;
          }

          // if(this.rows.userdetails[0].loginType == "regular"){

            // if(this.rows.userdetails[0].roleName == "QPSetter"){
            //   this.router.navigate(['/qp-process/qp-type']);
            // }
            // if(this.rows.userdetails[0].roleName == "HOD"){
            //   this.router.navigate(['/qp-process/qp-type']);
            // }
            // if(this.rows.userdetails[0].roleName == "HOD"){
            //   if(this.rows.userdetails[0].degree == "UG"){
            //     this.router.navigate(['/assessments/hodinternalmarks']);
                
            //   }
            //   if(this.rows.userdetails[0].degree == "PG"){
            //     this.router.navigate(['/post-exam/approvegrades']);
            //   }
              
              this.accessserviceapi.AccessLog(this.rows.userdetails[0].name, "Login", "0.0.0.0",this.rows.userdetails[0].loginType).subscribe(resp => {
                this.rows2=resp.body;
                if(this.rows2.status == 1){
                 
                }else{
                  
                }
              });
              
           // }
            // if(this.rows.userdetails[0].roleName == "AR"){
            //   this.router.navigate(['/pre-exam/teacher-coursemapping']);
            // }
            // if(this.rows.userdetails[0].roleName == "Librarian"){
            //   this.router.navigate(['/assessments/libraryApproval']);
            // }
            // if(this.rows.userdetails[0].roleName == "Warden"){
            //   this.router.navigate(['/assessments/hostelApproval']);
            // }
            // if(this.rows.userdetails[0].roleName == "Finance"){
            //   this.router.navigate(['/dashboard/comptroller']);    
            // }
            // if(this.rows.userdetails[0].roleName == "Dean"){
            //   if(this.rows.userdetails[0].degree == "PG"){
            //     this.router.navigate(['/pgforms/formsapproval']);
            //   }  
            // }
            // if(this.rows.userdetails[0].roleName == "Dean"){
            //   if(this.rows.userdetails[0].degree == "UG"){
            //     this.router.navigate(['/assessments/deansapprovalug']);
            //   }  
            // }
            // if(this.rows.userdetails[0].roleName == "AR"){
            //   if(this.rows.userdetails[0].degree == "PG"){
            //     this.router.navigate(['/dashboard/pgdashboard']);
            //   }  
            // }
            // if(this.rows.userdetails[0].roleName == "Finance"){
            //   if(this.rows.userdetails[0].degree == "PG"){
            //     this.router.navigate(['/dashboard/pgdashboard']);
            //   }  
            // }
            // if(this.rows.userdetails[0].roleName == "Registrar"){
            //   if(this.rows.userdetails[0].degree == "PG"){
            //     this.router.navigate(['/pgforms/formsapproval']);
            //   }  
            // }
            // if(this.rows.userdetails[0].roleName == "Registrar"){
            //   if(this.rows.userdetails[0].degree == "UG"){
            //     this.router.navigate(['/convocations/donor-master']);
            //   }  
            // }
            if(this.rows.userdetails[0].roleName == "Admin" && this.rows.userdetails[0].loginType == "regular"){
              if(this.rows.userdetails[0].degree == "UG"){
                this.router.navigate(['/dashboard/university']);
                // this.configureDropdown() 
                this.accessserviceapi.AccessLog(this.rows.userdetails[0].username, "Login", "0.0.0.0",this.rows.userdetails[0].roleName).subscribe(resp => {
                  this.rows2=resp.body;
                  if(this.rows2.status == 1){
               
                  }else{
           
                  }
                });
              
            
              }
              if(this.rows.userdetails[0].degree == "PG"){
                this.router.navigate(['/dashboard/university']);
               // this.configureDropdown()
                this.accessserviceapi.AccessLog(this.rows.userdetails[0].username, "Login", "0.0.0.0",this.rows.userdetails[0].roleName).subscribe(resp => {
                  this.rows2=resp.body;
                  if(this.rows2.status == 1){
                 
                  }else{
                
                  }
                });
              
          
              }
            }else{
              this.serviceapi
              .getModules(this.rows.userdetails[0].roleId)
              .subscribe((resp1) => {
                this.rows1 = resp1.body;
          
                if (this.rows1.status == 1) {
                  localStorage.setItem(
                    "rolesdetails",
                    JSON.stringify(this.rows1.details)
                  );

             
                  if (
                    this.rows.userdetails[0].loginType == "regular" &&
                    this.rows.userdetails[0].roleName == this.rows1.role
                  ) {
                    this.router.navigate([this.rows1.NavigatingURL]);
                 
                    this.accessserviceapi.AccessLog(this.rows.userdetails[0].username, "Login", "0.0.0.0",this.rows.userdetails[0].roleName).subscribe(resp => {
                      this.rows2=resp.body;
                      if(this.rows2.status == 1){
                
                      }else{
                  
                      }
                    });
                      // alertifyjs.set('notifier', 'position', 'top-right');
                      // alertifyjs.success(data.message);
                  }
                }
            });
              
            }
        
          
         
          
          alertifyjs.set('notifier', 'position', 'top-right');
          alertifyjs.success(this.rows.message);        //Resetting the Form Values after the save - Deepak
        } else {

        alertifyjs.set('notifier', 'position', 'top-right');
        alertifyjs.error(this.rows.message);
        }
    },
    (error: string) => {
      this.error = error ? error : '';
    });
  


}

configureDropdown(){
    const dialogRef = this.dialog.open(ConfigurationdropdownComponent, {
      width: "1000px",
      height: "500px",
      data: {
        title: "Create configuration!",
        button: "submit"
      },
    });
    dialogRef.afterClosed().subscribe(() => {
      
    });
}
  }

