
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as alertifyjs from 'alertifyjs';
import { first } from 'rxjs/operators';
import { Subject } from 'rxjs/internal/Subject';
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { AccessservicelogService } from 'src/app/accessservicelog.service';
import { ServiceapiService } from 'src/app/serviceapi.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfigurationdropdownComponent } from '../configurationdropdown/configurationdropdown.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-facultylogin',
  templateUrl: './facultylogin.component.html',
  styleUrls: ['./facultylogin.component.scss','./facultylogin.component.css']
})
export class FacultyloginComponent implements OnInit ,OnDestroy {
  form: FormGroup;
  rows: any = [];
  loading:boolean=false
  hide = true;
  private unsubscribe$: Subject<void> = new Subject<void>(); // Use Subject<void>
  error = '';
 
  validationMessages = {
    email: [{ type: 'required', message: 'This field is required' }],
    password: [{ type: 'required', message: 'This field is required' }],
    
  };
  constructor(private formBuilder: FormBuilder,public dialog: MatDialog,private router: Router,  private serviceapi: ServiceapiService,private accessserviceapi:AccessservicelogService) { 
    this.form = this.formBuilder.group({
      email: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required),
    
    });
  }

  ngOnInit() {

   
   }
   ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
   emailValue:any
   passwordValue:any
  submitLogin(){
   
    const emailControl = this.form.get("email");
    const passwordControl = this.form.get("password");
    if (emailControl && emailControl.value) {
     this.emailValue = emailControl.value;
      
    } else {
     
    }
    if (passwordControl && passwordControl.value) {
      this.passwordValue = passwordControl.value;
       
     } else {
      
     }
    this.serviceapi
    .login(this.emailValue, this.passwordValue, "staff")
    .pipe(
      first(),
      takeUntil(this.unsubscribe$) // Automatically unsubscribes when component is destroyed
    ).subscribe(
            (data: any) => {
            
              this.rows = data;
        if (this.rows.status == 1) {
          if(this.rows.userdetails[0].loginType == "staff"){
            if(this.rows.userdetails[0].degree == "UG"){
              this.router.navigate(['/faculty/internalmarksupload']);
            }
            if(this.rows.userdetails[0].degree == "PG"){
              this.router.navigate(['/faculty/updategradepoint']);
            }
            this.accessserviceapi.AccessLog(this.rows.userdetails[0].name, "Login", "0.0.0.0",this.rows.userdetails[0].loginType).subscribe(resp => {
              this.rows=resp.body;
              if(this.rows.status == 1){
            
              }else{
                
              }
            });;
          }
      
      
          localStorage.setItem('currentUser', JSON.stringify(this.rows.userdetails));
          localStorage.setItem('authToken', JSON.stringify(this.rows.userdetails[0].token));
         
          
          alertifyjs.set('notifier', 'position', 'top-right');
          alertifyjs.success(this.rows.message);        //Resetting the Form Values after the save - Deepak
        } else {
     
        alertifyjs.set('notifier', 'position', 'top-right');
        alertifyjs.error(this.rows.message);
        }
    },
    (error: string) => {
      this.error = error ? error : '';
    });
  


}

  }

