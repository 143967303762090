import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AccessservicelogService {

  constructor(private http: HttpClient) { }
  rows: any = [];

  AccessLog(exUserName:any, exName:any, exIPAddress:any,logintype:any) : Observable<HttpResponse<any[]>> {
    return this.http.post<any[]>(
      environment.endpoint + "/academic/saveLogDetails",{
        exUserName:exUserName,
        exName:exName,
        exIPAddress:exIPAddress,
        logintype:logintype
      }, { observe: 'response' });
    }

}
