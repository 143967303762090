import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoaderService } from './loader.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  constructor(public loaderService: LoaderService) { }
  accessToken:any
  access:any
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      this.loaderService.show();
      this.accessToken = localStorage.getItem('authToken');
      this.access=JSON.parse(this.accessToken)
      if (this.access) {
         req = req.clone({ headers: req.headers.set('Authorization', `Bearer ${this.access}`)}
        )
      } 
      return next.handle(req).pipe(
          // finalize(() => this.loaderService.hide())
      );
  }

}
