<!-- Container-fluid starts -->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12 ">
        <br>
        <br>
            <div class="card polaroid">
                <div class="card-header" style="background-color: #2A3142">
                    <h5 style="color: white;">Update Student Photo </h5>
                </div>
                <div class="card-body">
                    <form class="needs-validation" [formGroup]="forms">
                       
                                            <div class="row">
                                              
                                                <div class="col-md-6 mb-3 kt-margin-bottom-20-mobile" >
                                                    <label for="formFile">Select Photo(JPG,PNG and JPEG) <h3 style="color:red;display: inline">*
                                                  </h3></label>
                                                  <input input class="form-control" type="file" id="formFile" name="coursefile" #fileInput (change)="onFileSelect($event)" />
                                                 
                                                  </div>
                          
                       




                           
                        </div>
                        <div style="text-align: center;">
                            <button class="btn btn-info btn-lg" type="button" (click)="uploadStudentPhoto()" placement="top" ngbTooltip="btn btn-info"
                                *ngIf="saveBtn" [disabled]="!forms.valid || loading6">
                                Update<i class="fa fa-spinner fa-spin" *ngIf="loading6"></i></button>
                          
                        </div>
                    </form>
                </div>
            </div>

            </div>

        </div>
    </div>
<!-- </div> -->
<!-- Container-fluid Ends -->



