<!-- Container-fluid starts -->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12 ">
            <br>
            <div class="card-header">
                <h4 style="font-weight: bold;">CONFIGURATION</h4>

            </div>
            <br>
            <div class="card polaroid">

                <div class="card-body">
                    <form class="needs-validation" [formGroup]="forms">
                        <div class="row">
                            <div class="col-md-3 mb-3 kt-margin-bottom-20-mobile" [ngClass]="{
                                'has-error':
                                    forms.get('college')?.invalid &&
                                    forms.get('college')?.touched
                            }">
                                <label for="validationCustom01">College <h3 style="color:red;display: inline">*
                                    </h3></label>
                                <select placeholder="Select College *" matNativeControl
                                    class="form-control select-with-arrow" formControlName="college" [id]="'college'">
                                    <option value="" disabled selected hidden>Select College *</option>
                                    <option *ngFor="let item of collegeLists" [value]="item.id">
                                        {{ item.exName }}
                                    </option>
                                </select>
                                <ng-container *ngFor="
                                    let validation of validationMessages.college
                                ">
                                    <div class="help-block" *ngIf="
                                        forms
                                            .get('college')
                                            ?.hasError(validation.type) &&
                                        (forms.get('college')?.dirty ||
                                            forms.get('college')?.touched)
                                    ">
                                        <p style="color: red">
                                            {{ validation.message }}
                                        </p>
                                    </div>
                                </ng-container>
                            </div>
                            <div class="col-md-3 mb-3 kt-margin-bottom-20-mobile" [ngClass]="{
                                                  'has-error':
                                                      forms.get('degree')?.invalid &&
                                                      forms.get('degree')?.touched
                                              }">
                                <label for="validationCustom01">Degree <h3 style="color:red;display: inline">*
                                    </h3></label>
                                <select placeholder="Select Degree *" matNativeControl
                                    class="form-control select-with-arrow" formControlName="degree" [id]="'degree'">
                                    <option value="" disabled selected hidden>Select Degree *</option>
                                    <option *ngFor="let item of degreeList" [value]="item.id">
                                        {{ item.exName }}
                                    </option>
                                </select>
                                <ng-container *ngFor="
                                                      let validation of validationMessages.degree
                                                  ">
                                    <div class="help-block" *ngIf="
                                                          forms
                                                              .get('degree')
                                                              ?.hasError(validation.type) &&
                                                          (forms.get('degree')?.dirty ||
                                                              forms.get('degree')?.touched)
                                                      ">
                                        <p style="color: red">
                                            {{ validation.message }}
                                        </p>
                                    </div>
                                </ng-container>
                            </div>
                            <div class="col-md-3 mb-3 kt-margin-bottom-20-mobile" [ngClass]="{
                                'has-error':
                                    forms.get('academicyear')?.invalid &&
                                    forms.get('academicyear')?.touched
                            }">
                                <label for="validationCustom01">Academic Year <h3 style="color:red;display: inline">*
                                    </h3></label>
                                <select placeholder="Select Academic Year *"  class="form-control select-with-arrow"
                                    formControlName="academicyear" [id]="'academicyear'">
                                    <option value="" disabled selected hidden>Select Academic Year *</option>
                                    <option *ngFor="let item of academicyearLists" [value]="item.id">
                                        {{ item.exYear }}
                                    </option>
                                </select>
                                <ng-container *ngFor="
                                    let validation of validationMessages.academicyear
                                ">
                                    <div class="help-block" *ngIf="
                                        forms
                                            .get('academicyear')
                                            ?.hasError(validation.type) &&
                                        (forms.get('academicyear')?.dirty ||
                                            forms.get('academicyear')?.touched)
                                    ">
                                        <p style="color: red">
                                            {{ validation.message }}
                                        </p>
                                    </div>
                                </ng-container>
                            </div>

                            <div class="col-md-3 mb-3 kt-margin-bottom-20-mobile" [ngClass]="{
                                                    'has-error':
                                                        forms.get('program')?.invalid &&
                                                        forms.get('program')?.touched
                                                }">
                                <label for="validationCustom01">Program <h3 style="color:red;display: inline">*
                                    </h3></label>
                                <select placeholder="Select Program *" 
                                    class="form-control select-with-arrow" formControlName="program" [id]="'program'">
                                    <option value="" disabled selected hidden>Select Program *</option>
                                    <option *ngFor="let item of programList" [value]="item.id">
                                        {{ item.exName
                                        }}
                                    </option>
                                </select>
                                <ng-container *ngFor="
                                                        let validation of validationMessages.program
                                                    ">
                                    <div class="help-block" *ngIf="
                                                            forms
                                                                .get('program')
                                                                ?.hasError(validation.type) &&
                                                            (forms.get('program')?.dirty ||
                                                                forms.get('program')?.touched)
                                                        ">
                                        <p style="color: red">
                                            {{ validation.message }}
                                        </p>
                                    </div>
                                </ng-container>
                            </div>

                        </div>
                        <div class="row">
                            <!-- <div class="col-md-3 mb-3 kt-margin-bottom-20-mobile" [ngClass]="{
                                'has-error':
                                    forms.get('discipline')?.invalid &&
                                    forms.get('discipline')?.touched
                            }">
                                <label for="validationCustom01">Discipline <h3 style="color:red;display: inline">*
                                    </h3><i class="fa fa-spinner fa-spin" *ngIf="load"></i></label>
                                <select placeholder="Select Discipline *" class="form-control select-with-arrow"
                                    formControlName="discipline" [id]="'discipline'">
                                    <option value="" disabled selected hidden>Select Discipline *</option>
                                    <option *ngFor="let item of disciplineList" [value]="item.id">
                                        {{ item.exName
                                        }}
                                    </option>
                                </select>
                                <ng-container *ngFor="
                                    let validation of validationMessages.discipline
                                ">
                                    <div class="help-block" *ngIf="
                                        forms
                                            .get('discipline')
                                            ?.hasError(validation.type) &&
                                        (forms.get('discipline')?.dirty ||
                                            forms.get('discipline')?.touched)
                                    ">
                                        <p style="color: red">
                                            {{ validation.message }}
                                        </p>
                                    </div>
                                </ng-container>
                            </div> -->

                            <div class="col-md-3 mb-3 kt-margin-bottom-20-mobile" [ngClass]="{
                                'has-error':
                                  forms.get('programyear')?.invalid &&
                                  forms.get('programyear')?.touched
                              }">
                                <label for="validationCustom01">Program Year <h3 style="color:red;display: inline">*
                                    </h3></label>
                                <select placeholder="Select Program Year *" class="form-control select-with-arrow"
                                    formControlName="programyear"  [id]="'programyear'">
                                    <option value="" disabled selected hidden>Select Program Year *</option>
                                    <option *ngFor="let item of programyearLists" [value]="item.id">
                                        {{ item.exName }}
                                    </option>
                                </select>
                                <ng-container *ngFor="
                                                      let validation of validationMessages.programyear
                                                  ">
                                    <div class="help-block" *ngIf="
                                                          forms
                                                              .get('programyear')
                                                              ?.hasError(validation.type) &&
                                                          (forms.get('programyear')?.dirty ||
                                                              forms.get('programyear')?.touched)
                                                      ">
                                        <p style="color: red">
                                            {{ validation.message }}
                                        </p>
                                    </div>
                                </ng-container>
                            </div>

                            <div class="col-md-3 mb-3 kt-margin-bottom-20-mobile" [ngClass]="{
                                                                      'has-error':
                                                                          forms.get('semester')?.invalid &&
                                                                          forms.get('semester')?.touched
                                                                  }">
                                <label for="validationCustom01">Semester <h3 style="color:red;display: inline">*
                                    </h3></label>
                                <select placeholder="Select Semester *" matNativeControl
                                    class="form-control select-with-arrow" formControlName="semester"  [id]="'semester'">
                                    <option value="" disabled selected hidden>Select Semester *</option>
                                    <option *ngFor="let item of semesterLists" [value]="item.id">
                                        {{ item.exName }}
                                    </option>
                                </select>
                                <ng-container *ngFor="
                                                                          let validation of validationMessages.semester
                                                                      ">
                                    <div class="help-block" *ngIf="
                                                                              forms
                                                                                  .get('semester')
                                                                                  ?.hasError(validation.type) &&
                                                                              (forms.get('semester')?.dirty ||
                                                                                  forms.get('semester')?.touched)
                                                                          ">
                                        <p style="color: red">
                                            {{ validation.message }}
                                        </p>
                                    </div>
                                </ng-container>
                            </div>
                            <!-- <div class="col-md-3 mb-3 kt-margin-bottom-20-mobile" [ngClass]="{
                                'has-error':
                                    forms.get('course')?.invalid &&
                                    forms.get('course')?.touched
                            }">
                                <label for="validationCustom01">Course <h3 style="color:red;display: inline">*
                                    </h3><i class="fa fa-spinner fa-spin" *ngIf="load2"></i></label>
                                <select placeholder="Select Course *" matNativeControl
                                    class="form-control select-with-arrow" formControlName="course" [id]="'course'">
                                    <option value="" disabled selected hidden>Select Course *</option>
                                    <option *ngFor="let item of courseList" [value]="item.id">
                                        {{item.exCoursesCode}} :: {{ item.exName }}
                                    </option>
                                </select>
                                <ng-container *ngFor="
                                    let validation of validationMessages.course
                                ">
                                    <div class="help-block" *ngIf="
                                        forms
                                            .get('course')
                                            ?.hasError(validation.type) &&
                                        (forms.get('course')?.dirty ||
                                            forms.get('course')?.touched)
                                    ">
                                        <p style="color: red">
                                            {{ validation.message }}
                                        </p>
                                    </div>
                                </ng-container>
                            </div> -->



                        </div>
                        <!-- <div class="row">
                            <div class="col-md-3 mb-3 kt-margin-bottom-20-mobile" [ngClass]="{
                                'has-error':
                                    forms.get('department')?.invalid &&
                                    forms.get('department')?.touched
                            }">
                                <label for="validationCustom01">Department <h3 style="color:red;display: inline">*
                                    </h3></label>
                                <select placeholder="Select Department *" matNativeControl
                                    class="form-control select-with-arrow" formControlName="department" [id]="'department'">
                                    <option value="" disabled selected hidden>Select Department *</option>
                                    <option *ngFor="let item of row_departmentlist" [value]="item.id">
                                        {{item.exDepartmentName}}
                                    </option>
                                </select>
                                <ng-container *ngFor="
                                    let validation of validationMessages.department
                                ">
                                    <div class="help-block" *ngIf="
                                        forms
                                            .get('department')
                                            ?.hasError(validation.type) &&
                                        (forms.get('department')?.dirty ||
                                            forms.get('department')?.touched)
                                    ">
                                        <p style="color: red">
                                            {{ validation.message }}
                                        </p>
                                    </div>
                                </ng-container>
                            </div>
                        </div> -->
                        <div style="text-align: center;">
                            <button class="btn btn-info btn-lg" type="button" placement="top" ngbTooltip="btn btn-info"
                                [disabled]="!forms.valid || loading5" (click)="submitConfiguration()">
                                Submit<i class="fa fa-spinner fa-spin" *ngIf="loading5"></i></button>

                        </div>
                    </form>
                </div>
            </div>

        </div>

    </div>
</div>
<!-- </div> -->
<!-- Container-fluid Ends -->