
import { Component, ViewChild, OnInit, Inject, ViewEncapsulation, PLATFORM_ID } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { APP_BASE_HREF, DatePipe, isPlatformBrowser } from '@angular/common';
import { SelectionModel } from '@angular/cdk/collections'
import { environment } from '../../../../environments/environment';
import * as moment from 'moment';
import * as alertifyjs from "alertifyjs";
import { DataSource } from '@angular/cdk/collections';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ServiceapiService } from 'src/app/serviceapi.service';
//To Define the Date format - Deepak
export const DD_MM_YYYY_Format = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.scss']
})
export class ChangepasswordComponent implements OnInit {
  butDisabled: boolean = true;
  collegeslist = new Array();
  degreeslist = new Array();
  academicyearlist = new Array();
  programmelist = new Array();
  programyearlist = new Array();
  disciplinelist = new Array();
  semesterlist = new Array();
  sectionlist = new Array();
  categorylist = new Array();
  dateOfBirth: any;
  dateOfJoining: any;
  forms: FormGroup;
  progress = '0';
  isBrowser: boolean;
  rows: any = [];
  mySnackBarRef: any;
  temp: any = [];
  saveBtn: boolean = true;
  loading: boolean = false;
  id: any = null;
  title: '';
  placeholder = 'https://via.placeholder.com/190x190';

  validationMessages = {
    college: [
      { type: 'required', message: 'This field is required.' }
    ],
    degree: [
      { type: 'required', message: 'This field is required.' }
    ],
    academicYear: [
      { type: 'required', message: 'This field is required.' }
    ],

  };
  constructor(public dialogRef: MatDialogRef<ChangepasswordComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog, private fb: FormBuilder,
   public formBuilder: FormBuilder, private http: HttpClient, @Inject(PLATFORM_ID) private platformId: Object,    private serviceapi: ServiceapiService,
  ) {

    this.forms = formBuilder.group({
      // personal
      college: new FormControl('', Validators.required),
      degree:  new FormControl('', Validators.required),
      academicYear:  new FormControl('', Validators.required),
    
    });
    this.isBrowser = isPlatformBrowser(platformId);
  }
 
  ngOnInit() {
  

  }
  update(): void {
    this.loading=true
    this.serviceapi.changepassword(
    this.forms.get('college')?.value,
     btoa(this.forms.get('degree')?.value),
     btoa(this.forms.get('academicYear')?.value),
    this.data.logintype,
    
     ).subscribe(data => {
        this.rows = data.body;

        if (this.rows.status == 1) {
          this.dialogRef.close();
          alertifyjs.set("notifier", "position", "top-right");
        alertifyjs.success(this.rows.message);
        this.loading=false
        }else {
        //  this.dialogRef.close();
        alertifyjs.set("notifier", "position", "top-right");
        alertifyjs.error(this.rows.message);
        this.loading=false
        }
      });
  }
  

}
