<!-- Container-fluid starts -->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12 ">
            <br>
                <div class="card polaroid">
                <div class="card-header" style="background-color: #2A3142">
                    <h5 style="color: white;">View More</h5>
                </div>
                <div class="card-body">
                    <div class="row">
                      <div class="col-lg-1">
                          <p style="font-weight: bold;">Student Name :</p>
                          </div>
                          <div class="col-lg-3">
                          <label>{{studentName}}</label>
                          </div>
              
                    
                            <div class="col-lg-1">
                          <p style="font-weight: bold;">Student ID :</p>
                          </div>
                          <div class="col-lg-3">
                          <label>{{studentId}}</label>
                          </div>
            
                      
                            <div class="col-lg-1">
                          <p style="font-weight: bold;">Mobile :</p>
                          </div>
                          <div class="col-lg-3">
                          <label>{{studentMobile}}</label>
                          </div>
                        </div>
                     <br>
                        <div class="row">
                            <div class="col-lg-1">
                          <p style="font-weight: bold;">Email ID :</p>
                          </div>
                          <div class="col-lg-3">
                          <label>{{studentEmail}}</label>
                          </div>
                
                          <div class="col-lg-1">
                            <p style="font-weight: bold;">Program :</p>
                            </div>
                            <div class="col-lg-3">
                            <label>{{studentProgram}}</label>
                            </div>
                  
                            <div class="col-lg-1">
                                <p style="font-weight: bold;">Year :</p>
                                </div>
                                <div class="col-lg-3">
                                <label>{{studentYear}}</label>
                                </div>
                      </div>
<br>

<div class="row">
                                <div class="col-lg-1">
                                    <p style="font-weight: bold;">College :</p>
                                    </div>
                                    <div class="col-lg-3">
                                    <label>{{studentCollege}}</label>
                                    </div>
                          

                                    <div class="col-lg-1">
                                        <p style="font-weight: bold;">Academic Year :</p>
                                        </div>
                                        <div class="col-lg-3">
                                        <label>{{studentAcademicyear}}</label>
                                        </div>

                                        <div class="col-lg-1">
                                            <p style="font-weight: bold;">Admission Year :</p>
                                            </div>
                                            <div class="col-lg-3">
                                            <label>{{studentAdmission}}</label>
                                            </div>
                         
                        </div>
<br>
                        <div class="row">
                            <div class="col-lg-1">
                                <p style="font-weight: bold;">Degree :</p>
                                </div>
                                <div class="col-lg-3">
                                <label>{{studentdegree}}</label>
                                </div>
                                <div class="col-lg-1">
                                    <p style="font-weight: bold;">D.O.B :</p>
                                    </div>
                                    <div class="col-lg-3">
                                    <label>{{studentdateofbirth}}</label>
                                    </div>
                                    <div class="col-lg-1">
                                        <p style="font-weight: bold;">State :</p>
                                        </div>
                                        <div class="col-lg-3">
                                        <label>{{studentstate}}</label>
                                        </div>
                        </div>
                      <br/>
                        <div class="row">
                          <div class="col-lg-1">
                            <p style="font-weight: bold;">Category :</p>
                            </div>
                            <div class="col-lg-3">
                            <label>{{studentcategory}}</label>
                            </div>
                          </div>
                  </div>
            </div>


            </div>

        </div>
    </div>
<!-- </div> -->
<!-- Container-fluid Ends -->



