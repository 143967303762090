
import { HttpClient } from "@angular/common/http";
import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import * as alertifyjs from "alertifyjs";
import * as moment from "moment";
import { ServiceapiService } from "src/app/serviceapi.service";
import { environment } from "src/environments/environment";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { DD_MM_YYYY_Format } from "src/app/components/masters/dateformat.component";
import ka from "date-fns/esm/locale/ka/index";
import { Subscription, interval } from "rxjs";

export interface RowData {
  select: string;
  id: string;
  exFeeName: string;
  exAmount: string;
}

@Component({
  selector: 'app-configurationdropdown',
  templateUrl: './configurationdropdown.component.html',
  styleUrls: ['./configurationdropdown.component.scss']
})
export class ConfigurationdropdownComponent implements OnInit {
  private updateSubscription: Subscription;
  forms: FormGroup;
  forms1: FormGroup;
  userDetails: any;
  loading5: boolean = false;
  isLoading: boolean = false;
  load: boolean = false;
  load2: boolean = false;
  load3: boolean = false;
  isInputDisabled: boolean = false;
  schemess=new Array() 
  saveBtn: boolean = true;
  rows: any = [];
  temp: any = [];
  id: any = null;
  uid: any;
  row_department = new Array();
  degreeLists = new Array();
  programList= new Array();
  academicyearLists= new Array();
  courseLists= new Array();
  row_studentformat= new Array();
  programyearLists= new Array();
  semesterLists= new Array();
  categoryLists= new Array();
  collegeLists= new Array();
  thesiss:any
  showforPG:boolean=false
  getStartVal: any;
  dateOfBirth: any;
  collegeListdoLogin=new Array();
  collegeArray=new Array();
  degreeArray=new Array();
  programArray=new Array();
  academicArray=new Array();
  programyearArray=new Array();
  row_departmentlist=new Array();
  semesterArray=new Array();
  disciplineArray=new Array();
  disciplineList=new Array();
  courseArray=new Array();
  courseList=new Array();
  departmentArray=new Array();

  public displayedColumns = ['college','degree', 'academicYear', 'programme', 'format', 
  'status'];
  dataSourceTable: MatTableDataSource<RowData>;
  @ViewChild("paginatorTable") paginatorTable: MatPaginator;
  @ViewChild("matSort") sortTable: MatSort;
  validationMessages = {
    academicyear: [{ type: "required", message: "This field is required" }],
    degree: [{ type: "required", message: "This field is required" }],
    program: [{ type: "required", message: "This field is required" }],
    programyear: [{ type: "required", message: "This field is required" }],
    semester: [{ type: "required", message: "This field is required" }],
    college: [{ type: "required", message: "This field is required" }],
    // discipline: [{ type: "required", message: "This field is required" }],
    // course: [{ type: "required", message: "This field is required" }],
    // department: [{ type: "required", message: "This field is required" }],
  };
 


  constructor(
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private serviceapi: ServiceapiService,
    public dialogRef: MatDialogRef<ConfigurationdropdownComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.forms = this.formBuilder.group({
      academicyear: new FormControl("", Validators.required),
      degree: new FormControl("", Validators.required),
      program: new FormControl("", Validators.required),
      programyear: new FormControl("", Validators.required),
      semester: new FormControl("", Validators.required),
      college: new FormControl("", Validators.required),
      // discipline: new FormControl("", Validators.required),
      // course: new FormControl("", Validators.required),
      // department: new FormControl("", Validators.required),
    });
  

    
  }
  collegeList = new Array();
  degreeList = new Array();
  ngOnInit() {
    this.userDetails = localStorage.getItem("currentUser");
    //loading of dropdowns
     this.collegeListdoLogin = JSON.parse(this.userDetails)[0].collegeArray;

    this.getCollege()
    this.getDegree();
    this.getAcademicYear();
    this.getProgram();
   this.getProgramYear();
   this.getSemester();
  //  this.getDepartment();
  }

 getCollege() {
    this.serviceapi.colleges("get", "", "", "", "", "","","","","","","").subscribe((resp) => {
      this.rows = resp.body;
      if (this.rows.status == 1) {
        this.collegeList = this.rows.colleges;
        for (let i = 0; i <= this.collegeList.length - 1; i++) {
          for(let j=0;j<=this.collegeListdoLogin.length-1;j++){
            if (
              this.collegeList[i].id == this.collegeListdoLogin[j].id
            ) {
              this.collegeLists.push(this.collegeList[i]);
            }
          }
        
        }
      } else {
        alertifyjs.set("notifier", "position", "top-right");
        alertifyjs.error(this.rows.message);
      }
    });
  }
  getDegree() {
    this.serviceapi.degree("get", "", "", "", "", "").subscribe((resp) => {
      this.rows = resp.body;
      if (this.rows.status == 1) {
        this.degreeLists = this.rows.degree;
        for (let i = 0; i <= this.degreeLists.length - 1; i++) {
          if (
            this.degreeLists[i].exName == JSON.parse(this.userDetails)[0].degree
          ) {
            this.degreeList.push(this.degreeLists[i]);
          }
        }
      } else {
        alertifyjs.set("notifier", "position", "top-right");
        alertifyjs.error(this.rows.message);
      }
    });
  }

  getProgram() {
    this.load=true
    this.serviceapi
      .programOnDegree(JSON.parse(this.userDetails)[0].degreeId)
      .subscribe((resp) => {
        this.rows = resp.body;
        if (this.rows.status == 1) {
          this.programList = this.rows.details;
          this.load=false
        } else {
          alertifyjs.set("notifier", "position", "top-right");
          alertifyjs.error(this.rows.message);
          this.load=false
        }
      });
  }

  getAcademicYear() {
    this.serviceapi.academicyear("get", "", "", "", "", "","","","").subscribe((resp) => {
      this.rows = resp.body;
      if (this.rows.status == 1) {
        this.academicyearLists = this.rows.academicyear;
       
      } else {
        alertifyjs.set("notifier", "position", "top-right");
        alertifyjs.error(this.rows.message);
      }
    });
  }

  getProgramYear() {
    this.serviceapi.programyear("get", "", "", "", "", "","").subscribe((resp) => {
      this.rows = resp.body;
      if (this.rows.status == 1) {
        this.programyearLists = this.rows.programme;
       
      } else {
        alertifyjs.set("notifier", "position", "top-right");
        alertifyjs.error(this.rows.message);
      }
    });
  }

  getSemester() {
    this.serviceapi.semester("get", "", "", "", "", "").subscribe((resp) => {
      this.rows = resp.body;
      if (this.rows.status == 1) {
        this.semesterLists = this.rows.semster;
       
      } else {
        alertifyjs.set("notifier", "position", "top-right");
        alertifyjs.error(this.rows.message);
      }
    });
  }

  // getDiscipline() {
  //   this.load=true
  //   this.serviceapi
  //     .disciplineOnProgram(this.forms.get("program")?.value)
  //     .subscribe((resp) => {
  //       this.rows = resp.body;
  //       if (this.rows.status == 1) {
  //         this.load=false
  //         this.disciplineList = this.rows.courses;
  //       } else {
  //         this.load=false
  //         alertifyjs.set("notifier", "position", "top-right");
  //         alertifyjs.error(this.rows.message);
  //       }
  //     });
  // }
  // getCourse() {
  //   this.load2=true
  //   this.serviceapi
  //     .coursesonPAPS(this.forms.get("academicyear")?.value,this.forms.get("program")?.value,this.forms.get("programyear")?.value,this.forms.get("semester")?.value)
  //     .subscribe((resp) => {
  //       this.rows = resp.body;
  //       if (this.rows.status == 1) {
  //         this.courseList = this.rows.coursedetails;
  //         this.load2=false
  //       } else {
  //         alertifyjs.set("notifier", "position", "top-right");
  //         alertifyjs.error(this.rows.message);
  //         this.load2=false
  //       }
  //     });
  // }
  // getDepartment() {
  //   this.serviceapi.departmentbaseddegree(JSON.parse(this.userDetails)[0].degreeId).subscribe((resp) => {
  //     this.rows = resp.body;
  //     if (this.rows.status == 1) {
  //       for (let i = 0; i <= this.rows.details.length - 1; i++) {
  //         if(this.rows.details[i].degree == JSON.parse(this.userDetails)[0].degree){
  //             this.row_departmentlist.push(this.rows.details[i])
  //         }
     
  //     }
  //     } else {
  //       alertifyjs.set("notifier", "position", "top-right");
  //       alertifyjs.error(this.rows.message);
  //     }
  //   });
  // }

  //Form Reset
  formReset() {
   this.forms.get("fourdigit")?.reset();
   this.forms.get("last")?.reset();
   this.forms.get("middle")?.reset();
   this.forms.get("first")?.reset();
   this.forms.get("program")?.reset();
   this.forms.get("active")?.reset();
  }

 
 submitConfiguration(){
  for(var i=0;i<this.collegeList.length;i++){
  if(this.forms.get("college")?.value==this.collegeList[i].id){
      this.collegeArray.push(this.collegeList[i])
  }
}
for(var j=0;j<this.degreeList.length;j++){
  if(this.forms.get("degree")?.value==this.degreeList[j].id){
      this.degreeArray.push(this.degreeList[j])
  }
}
for(var k=0;k<this.programList.length;k++){
  if(this.forms.get("program")?.value==this.programList[k].id){
      this.programArray.push(this.programList[k])
  }
}
for(var l=0;l<this.academicyearLists.length;l++){
  if(this.forms.get("academicyear")?.value==this.academicyearLists[l].id){
      this.academicArray.push(this.academicyearLists[l])
  }
}
for(var m=0;m<this.programyearLists.length;m++){
  if(this.forms.get("programyear")?.value==this.programyearLists[m].id){
      this.programyearArray.push(this.programyearLists[m])
  }
}
for(var n=0;n<this.semesterLists.length;n++){
  if(this.forms.get("semester")?.value==this.semesterLists[n].id){
      this.semesterArray.push(this.semesterLists[n])
  }
}
// for(var o=0;o<this.disciplineList.length;o++){
//   if(this.forms.get("discipline")?.value==this.disciplineList[o].id){
//       this.disciplineArray.push(this.disciplineList[o])
//   }
// }
// for(var p=0;p<this.courseList.length;p++){
//   if(this.forms.get("course")?.value==this.courseList[p].id){
//       this.courseArray.push(this.courseList[p])
//   }
// }
// for(var q=0;q<this.row_departmentlist.length;q++){
//   if(this.forms.get("department")?.value==this.row_departmentlist[q].id){
//       this.departmentArray.push(this.row_departmentlist[q])
//   }
// }

  this.loading5=true
  localStorage.removeItem('commonCollege');
  localStorage.removeItem('commonDegree');
  localStorage.removeItem('commonProgram');
  localStorage.removeItem('commonAcademic');
  localStorage.removeItem('commonProgramyear');
  localStorage.removeItem('commonSemester');
  // localStorage.removeItem('commonDiscipline');
  // localStorage.removeItem('commonCourse');
  // localStorage.removeItem('commonDepartment');


  localStorage.setItem('commonCollege',JSON.stringify(this.collegeArray));
  localStorage.setItem('commonDegree',JSON.stringify(this.degreeArray));
  localStorage.setItem('commonProgram', JSON.stringify(this.programArray));
  localStorage.setItem('commonAcademic',JSON.stringify(this.academicArray));
  localStorage.setItem('commonProgramyear',JSON.stringify(this.programyearArray));
  localStorage.setItem('commonSemester',JSON.stringify(this.semesterArray));
  // localStorage.setItem('commonDiscipline',JSON.stringify(this.disciplineArray));
  // localStorage.setItem('commonCourse',JSON.stringify(this.courseArray));
  // localStorage.setItem('commonDepartment',JSON.stringify(this.departmentArray));
  this.loading5=false
  alertifyjs.set("notifier", "position", "top-right");
  alertifyjs.success("Configuration is Success");
  this.dialogRef.close();
  window.location.reload()
 }

}
