
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12 ">
         
            <form class="needs-validation" [formGroup]="forms">
            <div class="card polaroid">
            <br>
                <div class="card-header" style="background-color: #2A3142">
                    <h5 style="color: white;">Change Password</h5>
                </div>
                <div class="card-body">
                 
                    <div class="row">
        <div class="col-sm-4"></div>
        <div class="col-sm-5" 
          [ngClass]="{ 'has-error': ((forms.get('college')?.invalid) && (forms.get('college')?.touched)) }">
          <label for="validationCustom01">Login name <h3 style="color:red;display: inline">*
        </h3></label>
          <input type="text" class="form-control" formControlName="college" [id]="'college'" placeholder="Login name *">
          <ng-container *ngFor="let validation of validationMessages.college">
            <div class="help-block"
              *ngIf="forms.get('college')?.hasError(validation.type) && (forms.get('college')?.dirty || forms.get('college')?.touched)">
              <p style="color: red">
                  {{ validation.message }}
              </p>
            </div>
          </ng-container>
         </div>
</div>
         <div class="row">
          <div class="col-sm-4"></div>
        <div class="col-sm-5" [ngClass]="{ 'has-error': ((forms.get('degree')?.invalid) && (forms.get('degree')?.touched)) }">
            <label for="validationCustom01">Old Password <h3 style="color:red;display: inline">*
            </h3></label>
          <input type="password" class="form-control" formControlName="degree" [id]="'degree'" placeholder="Old Password *">
          <ng-container *ngFor="let validation of validationMessages.degree">
            <div class="help-block"
              *ngIf="forms.get('degree')?.hasError(validation.type) && (forms.get('degree')?.dirty || forms.get('degree')?.touched)">
              <p style="color: red">
                  {{ validation.message }}
              </p>
            </div>
          </ng-container>
        </div>
       </div>
       <div class="row">
          <div class="col-sm-4"></div>
        <div class="col-sm-5"
          [ngClass]="{ 'has-error': ((forms.get('academicYear')?.invalid) && (forms.get('academicYear')?.touched)) }">
          <label for="validationCustom01">New Password <h3 style="color:red;display: inline">*
        </h3></label>
          <input type="password" class="form-control" formControlName="academicYear" [id]="'academicYear'" placeholder="New Password *">
          <ng-container *ngFor="let validation of validationMessages.academicYear">
            <div class="help-block"
              *ngIf="forms.get('academicYear')?.hasError(validation.type) && (forms.get('academicYear')?.dirty || forms.get('academicYear')?.touched)">
              <p style="color: red">
                  {{ validation.message }}
              </p>
            </div>
          </ng-container>
        </div>
       </div>
     <br>
     <div class="row">
        <div class="col-sm-4"></div>
        <div class="col-sm-5">
        <button class="btn btn-info btn-lg" [disabled]="!forms.valid || loading" type="button" placement="top" ngbTooltip="btn btn-info"
        (click)="update()" >
        Change Password<i class="fa fa-spinner fa-spin" *ngIf="loading"></i></button>
    </div>
      </div>

</div>
</div>

    </form>
  </div>
</div>
</div>