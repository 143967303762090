<!-- footer start-->
<div class="container-fluid">
  <div class="row">
    <div class="col-md-6 footer-copyright">
      <p class="mb-0">©2020 UAS(B) | Funded By:<a href="https://icar.org.in/" style="color: #0080FF" alt="ICAR(New Delhi), UAS(Bengaluru)" 
        title="Click this will open the site in new tab." target="_blank">ICAR(New Delhi), UAS(Bengaluru)</a></p>
    </div>

    <div class="col-md-6">
      <p class="pull-right mb-0">Powered by : <a href="https://spherebox.tech/" style="color: #0080FF" alt="spherebox.in" 
        title="Click this will open the site in new tab." target="_blank"> Spherebox Technologies Private Limited</a></p>
    </div>
  </div>
</div>
