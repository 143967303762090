<!DOCTYPE html>
<html>
<head>
	<title>Animated Login Form</title>
	<!-- <link rel="stylesheet" type="text/css" href="css/style.css"> -->
	<link href="https://fonts.googleapis.com/css?family=Poppins:600&display=swap" rel="stylesheet">
	<!-- <script src="https://kit.fontawesome.com/a81368914c.js"></script> -->
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css">
	<meta name="viewport" content="width=device-width, initial-scale=1">
</head>
<body>
    <div class="center-container">
    <div class="card3">
        <img src="/assets/images/UASLOGO.jpg" alt="Logo" class="logo">
	<img class="wave" src="/assets/images/wave.png">
	<div class="container3">
		<div class="imgs">
			<img src="/assets/images/bg.svg">
		</div>
		<div class="login-content">
			<form clss="form1" [formGroup]="form">
				<img src="/assets/images/avatar.svg">
				<h3 class="title"> STUDENT</h3>
                <br>
           		<div class="input-div one">
           		   <div class="i">
           		   		<i class="fas fa-user"></i>
           		   </div>
           		   <div class="div">
                    <input type="text" formControlName="email" class="input" placeholder=" ">
                    <label for="username">Username</label>
           		   </div>
           		</div>
           		<div class="input-div pass">
           		   <div class="i"> 
           		    	<i class="fas fa-lock"></i>
           		   </div>
           		   <div class="div">
                    <input type="password" formControlName="password" class="input" placeholder=" "
                    [type]="hide ? 'password' : 'text'"
                   
                   />
                   <label for="username">Password</label>
                    <mat-icon class="adjustIcon"  color="primary" matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                  
            	   </div>
            	</div>
             
            	<button type="submit" [disabled]="!form.valid || loading" (click)="submitLogin()" class="btnx" >Login<i class="fa fa-spinner fa-spin" *ngIf="loading"></i></button>
              <div class="link-container">
                <button class="btnu" type="submit"><a class="as" href="/authentication/prelogin">Back</a></button>
                <a class="as" href="/authentication/forgetpasswordsstudents">Forgot Password?</a>
              </div>
            </form>
        </div>
    </div>

   
  
    
    <script type="text/javascript" src="/js/main.js"></script>
    </div>
    </div>
	<div class="row">
		<div class="col-md-6 footer-copyright" >
			<p class="mb-0">©2020 UAS(B) | Funded By:<a href="https://icar.org.in/" style="color: #0080FF" alt="ICAR(New Delhi), UAS(Bengaluru)" 
			  title="Click this will open the site in new tab." target="_blank">ICAR(New Delhi), UAS(Bengaluru)</a></p>
		  </div>
		 
	  
		  <div class="col-md-6" >
			<p class="pull-right mb-0">Powered by : <a href="https://spherebox.tech/" style="color: #0080FF" alt="spherebox.in" 
			  title="Click this will open the site in new tab." target="_blank"> Spherebox Technologies Private Limited</a></p>
		  </div>

	</div>
</body>
</html>
