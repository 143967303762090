
import { HttpClient } from "@angular/common/http";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import * as alertifyjs from "alertifyjs";
import { ServiceapiService } from "src/app/serviceapi.service";
import { environment } from "src/environments/environment";
import Swal from "sweetalert2/dist/sweetalert2.js";

export interface RowData {
  select: string;
  id: string;
  exFeeName: string;
  exAmount: string;
}

@Component({
  selector: 'app-viewmore',
  templateUrl: './viewmore.component.html',
  styleUrls: ['./viewmore.component.scss']
})
export class ViewmoreComponent implements OnInit {
  forms: FormGroup;
  userDetails: any;
  loading5: boolean = false;
  loading6: boolean = false;
  load: boolean = false;
  isLoading: boolean = false;
  saveBtn: boolean = true;
  rows: any = [];
  temp: any = [];
  id: any = null;
  uid: any;
  row_department = new Array();
  degreeLists = new Array();
  programList= new Array();
  academicyearLists= new Array();
  studentDetailsList= new Array();
  courseLists= new Array();
  row_studentformat= new Array();
  getStartVal: any;
  @ViewChild('fileInput', { static: false }) fileInput: ElementRef;
  public displayedColumns = ['college','degree', 'academicYear', 'programme', 'format', 
  'status'];
  dataSourceTable: MatTableDataSource<RowData>;
  @ViewChild("paginatorTable") paginatorTable: MatPaginator;
  @ViewChild("matSort") sortTable: MatSort;
 
  collegeDetails:any;
  degreeDetails:any;
  programDetails:any;
  academicyearDetails:any;
  programyearDetails:any;
  studentcategory:any;
  semesterDetails:any;
  collegeName:any;
  degreeName:any;
  prpgramName:any;
  academicyearName:any;
  programyearName:any;
  semesterName:any;
  studentName: any;
  studentId: any;
  studentMobile: any;
  studentEmail: any;
  studentProgram: any;
  studentYear: string;
  studentCollege: any;
  studentAdmission: any;
  studentAcademicyear: any;
  studentdegree: any;
  studentdateofbirth: any;
  studentstate: any;

  constructor(
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private serviceapi: ServiceapiService
  ) {
    this.forms = this.formBuilder.group({
  
    });
  }
 
  ngOnInit() {
    this.userDetails = localStorage.getItem("currentUser");
 
    this.studentName=JSON.parse(this.userDetails)[0].name
    this.studentId=JSON.parse(this.userDetails)[0].studentId
    this.studentMobile=JSON.parse(this.userDetails)[0].mobile
    this.studentEmail=JSON.parse(this.userDetails)[0].email
    this.studentProgram=JSON.parse(this.userDetails)[0].programme
    this.studentYear=JSON.parse(this.userDetails)[0].programmeyear +'Year'+ '-' +JSON.parse(this.userDetails)[0].semester+'Sem'
    this.studentCollege=JSON.parse(this.userDetails)[0].college
    this.studentAdmission=JSON.parse(this.userDetails)[0].admissionYear
    this.studentAcademicyear=JSON.parse(this.userDetails)[0].academicyear
    this.studentdegree=JSON.parse(this.userDetails)[0].degree
    this.studentdateofbirth=JSON.parse(this.userDetails)[0].dateOfBirth
    this.studentstate=JSON.parse(this.userDetails)[0].state
    this.studentcategory=JSON.parse(this.userDetails)[0].category

  }

}
